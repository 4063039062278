import { AppBar, Toolbar, Typography } from '@material-ui/core';

const UiAppBar = () => {

    return (
        <AppBar position="static" justify="left">
            <Toolbar>
                <Typography variant="h6" >
                    Kwiqhealth
                </Typography>
            </Toolbar>
        </AppBar>
    )
};

export default UiAppBar;
