import React, { useState } from 'react';

import Home from './components/Home/Home'
import { Container } from '@material-ui/core';
import UiAppBar from './components/UI/UiAppBar'

import classes from './App.module.css';
import Users from './components/Users/Users';
import UseSearch from './components/Home/UseSerach';

const users = require('./Applicants.json');

function App() {

  const [filteredUserList, setFilteredUserList] = useState([]);
  const [showInitMessage, setShowInitMessage] = useState(true);

  const updateUserList = (filterConditions) => {
    setShowInitMessage(false);
    let filetredList = users.filter(user => {
      return (user.department === filterConditions.department && user.experience >= parseInt(filterConditions.experience[0]) && user.experience <= parseInt(filterConditions.experience[1]) && user.hiringType === filterConditions.hiringType && user.education === filterConditions.education);
    });
    setFilteredUserList(filetredList);

  };

  const resetUserList = () => {
    setFilteredUserList(users);
  };

  return (
    <div>
      <UiAppBar />
      <Container className={classes.container}>
        <Home onSelect={updateUserList} onShowAll={resetUserList} />
        {showInitMessage && <UseSearch />}
        {!showInitMessage && <Users users={filteredUserList} />}
      </Container>
    </div>
  );
}

export default App;
