import { Avatar, Grid, GridList, GridListTile, Typography, Card, CardActionArea, CardContent, CardActions, Button } from '@material-ui/core'


let getColumns = (width) => {
    let columns = 3;
    if (width <= 640) columns = 1;

    return columns;
};

let getUserEducation = (education) => {
    let educationDescription = {
        "student": "Current nursing student",
        "gnm": "GNM",
        "anm": "ANM",
        "pbbsc": "P.B.B.S.c Nursing",
        "msc": "M.S.c Nursing"
    }
    return educationDescription[education];
};

const Users = (props) => {
    let columns = getColumns(window.innerWidth);
    let isNoUser = (props.users.length > 0) ? false : true;

    return (
        <Grid >
            <Grid>
            {isNoUser && <GridListTile>
                    <Card justify="center" display="flex">
                        <Typography>No Applicants available for this criteria , please try changing the filter selection.</Typography>
                    </Card>

                </GridListTile>}
                
            </Grid>
            <GridList cellHeight="auto" spacing={20} cols={columns}>
               
                {props.users.map((user) => {
                    const userEducationDescription = getUserEducation(user.education);
                    return (<GridListTile key={user.id} >

                        <Card justify="center" display="flex" key={user.id}>
                            <Avatar justify="center">
                                {user.name.slice(0, 1)}
                            </Avatar>
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {user.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {user.department.toUpperCase()}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {user.experience} years experience
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {userEducationDescription}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {user.reason}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {user.address}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary">
                                    Schedule Interview
                            </Button>
                            </CardActions>
                        </Card>
                    </GridListTile>
                    )
                })}
            </GridList>
        </Grid>
    )
};

export default Users;



